/* TripDetail.css */

.trip-detail-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure full viewport height */
  position: relative;
  overflow: hidden; /* Prevent body overflow */
}

/* Map sits on top (at a dynamic height).
   Then .resizer, then the .places-container below. */
.map-container {
  position: relative;
  width: 100%;
  /* The height is set inline from TripDetail.js (mapHeight). */
}

.resizer {
  width: 100%;
  height: 5px;
  background: #ccc;
  cursor: row-resize;
  /* You can tweak styling or add a hover effect if needed. */
}

/* The container that holds places + (optionally) the itinerary. */
.places-container {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  /* The height is set inline from TripDetail.js (placesContainerHeight). */
}

/* Content section tabs */
.content-section-tabs {
  display: flex;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  padding: 8px;
  gap: 8px;
}

.content-section-tab {
  padding: 8px 16px;
  background: #e0e0e0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s, color 0.2s;
}

.content-section-tab:hover {
  background: #d0d0d0;
}

.content-section-tab.active {
  background: #1976d2;
  color: white;
}

/* Layout behaviors for different content sections */

/* Default for single section - takes full width */
.places-container .brainstorm-section,
.places-container .itinerary-section,
.places-container .details-section {
  width: 100%;
  height: 100%;
  overflow: auto;
}

/* Default layout is column */
.places-container {
  flex-direction: column;
}

/* When 2 sections are active, use row layout */
.places-container.brainstorm.itinerary,
.places-container.brainstorm.details,
.places-container.itinerary.details {
  flex-direction: row;
  align-items: flex-start;
}

/* When 2 sections are active, each takes 50% width */
.places-container.brainstorm.itinerary .brainstorm-section,
.places-container.brainstorm.itinerary .itinerary-section,
.places-container.brainstorm.details .brainstorm-section,
.places-container.brainstorm.details .details-section,
.places-container.itinerary.details .itinerary-section,
.places-container.itinerary.details .details-section {
  width: 50%;
  height: 100%;
  overflow: auto;
}

/* Responsive layout for mobile devices */
@media (max-width: 768px) {
  /* Force column layout on mobile */
  .places-container.brainstorm.itinerary,
  .places-container.brainstorm.details,
  .places-container.itinerary.details {
    flex-direction: column;
  }
  
  /* Each section takes full width and 50% height */
  .places-container.brainstorm.itinerary .brainstorm-section,
  .places-container.brainstorm.itinerary .itinerary-section,
  .places-container.brainstorm.details .brainstorm-section,
  .places-container.brainstorm.details .details-section,
  .places-container.itinerary.details .itinerary-section,
  .places-container.itinerary.details .details-section {
    width: 100%;
    height: 50%;
  }
}

/* The "menu button" for mobile */
.menu-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #1976d2;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 3px;
  cursor: pointer;
}
.menu-btn:hover {
  background: #1565c0;
}

/* The button to create/hide itinerary if you place it at bottom-right */
.itinerary-toggle-btn {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: #1976d2;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 3px;
  cursor: pointer;
}
.itinerary-toggle-btn:hover {
  background: #1565c0;
}

/* Section selector styles */
.section-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  overflow-x: auto;
}

.section-tab {
  padding: 8px 16px;
  background: #e0e0e0;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  transition: background-color 0.2s, color 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-tab-location {
  font-weight: 500;
}

.section-tab-dates {
  font-size: 9px;
  margin-top: 2px;
  opacity: 0.8;
}

.section-tab:hover {
  background: #d0d0d0;
}

.section-tab.active {
  background: #1976d2;
  color: white;
}
