/* ===== Modal Overlay & Container ===== */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-container {
  background: #fff;
  width: 450px;
  border-radius: 8px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 24px;
  max-height: 90vh;
  overflow-y: auto;
}

/* ===== Title ===== */
.modal-title {
  text-align: center;
  margin-bottom: 24px;
}

.modal-title h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

/* ===== Form Layout ===== */
.modal-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-row {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
}

.location-input-wrapper {
  flex: 1;
  min-width: 0;
  margin-right: 5px;
}

.location-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

/* Add space between location and people box */
.people-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  flex-shrink: 0;
  padding: 0 8px;
  margin-left: 8px;
}

.people-icon {
  color: #666;
}

.people-input {
  width: 60px;
  padding: 10px 4px;
  border: none;
  text-align: center;
  font-size: 1rem;
}

.people-input:focus {
  outline: none;
}

/* ===== Date Range Container ===== */
.dateRange-container {
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
  padding: 0;
}

/* Smaller grey labels, using 2-column grid for alignment */
.date-labels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  margin: 8px 16px 4px;
  gap: 16px;
}

.date-labels span {
  color: #666;
  font-size: 0.875rem;
}

/* The date display row (the two date inputs) */
.dateRange-container .rdrDateDisplay {
  display: flex !important;
  gap: 16px;
  margin: 0;
  padding: 0 0 12px;
  background: #fff;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
}

.dateRange-container .rdrDateDisplayItem {
  flex: 1;
  border-radius: 6px;
  background-color: #f8f9fa;
  margin: 0;
}

.dateRange-container .rdrDateDisplayItem input {
  width: 100%;
  text-align: center;
  color: #333;
}

/* Hide the calendar (including month nav) if collapsed */
.dateRange-container.collapsed .rdrMonths,
.dateRange-container.collapsed .rdrMonthAndYearWrapper {
  display: none;
}

/* Commented out the rule that hides the month nav by default */
/*
.dateRange-container .rdrMonthAndYearWrapper {
  display: none;
}
*/

.dateRange-container .rdrCalendarWrapper {
  width: 100% !important;
}

.dateRange-container .rdrMonth {
  width: 100% !important;
}

/* ===== Action Buttons ===== */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 8px;
}

.cancel-btn,
.submit-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-btn {
  background: #f0f0f0;
}

.cancel-btn:hover {
  background: #e0e0e0;
}

.submit-btn {
  background: #1976d2;
  color: #fff;
}

.submit-btn:hover {
  background: #1565c0;
}

/* ===== Trip Sections ===== */
.sections-container {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 12px;
  margin-top: 8px;
}

/* New header for Trip Sections with view toggle */
.sections-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.sections-header h4 {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 500;
}

.view-toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #1976d2;
}

.section-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 8px;
}

.section-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.section-color-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.section-location-wrapper {
  flex: 2;
  position: relative;
}

.section-location-input {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.85rem;
}

.remove-section-btn {
  background: none;
  border: none;
  color: #d32f2f;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.remove-section-btn:hover {
  background: #ffebee;
}

.section-date-range-text {
  flex: 1;
  cursor: pointer;
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 0.8rem;
  background-color: #f8f9fa;
  text-align: center;
  white-space: nowrap;
  margin-left: 8px;
}

.section-date-range-text:hover {
  background-color: #e9ecef;
}

.section-date-picker {
  margin-top: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

/* Full width calendar in sections */
.section-calendar .rdrCalendarWrapper,
.section-calendar .rdrMonth {
  width: 100% !important;
  max-width: 100% !important;
}

/* Force the primary selection color to have higher z-index */
.section-calendar .rdrDayStartPreview.rdrDayEndPreview.rdrDayInPreview,
.section-calendar .rdrDayStartPreview.rdrDayInPreview,
.section-calendar .rdrDayEndPreview.rdrDayInPreview,
.section-calendar .rdrDayInPreview {
  z-index: 1 !important;
}

.section-calendar .rdrDayStartOfMonth.rdrDayInRange.rdrDayToday.rdrDayStartOfWeek,
.section-calendar .rdrDayEndOfMonth.rdrDayInRange.rdrDayToday.rdrDayEndOfWeek,
.section-calendar .rdrDayInRange.rdrDayToday,
.section-calendar .rdrDayStartOfMonth.rdrDayInRange,
.section-calendar .rdrDayEndOfMonth.rdrDayInRange,
.section-calendar .rdrDayInRange,
.section-calendar .rdrDaySelected {
  z-index: 2 !important;
}

/* Dark blue selections should have priority */
.section-calendar .rdrDayStartOfMonth.rdrDayEndOfMonth.rdrDayStartOfWeek.rdrDayEndOfWeek.rdrDayHovered.rdrDayActive,
.section-calendar .rdrDayStartOfMonth.rdrDayEndOfMonth.rdrDayStartOfWeek.rdrDayEndOfWeek.rdrDayActive,
.section-calendar .rdrDayStartOfWeek.rdrDayActive,
.section-calendar .rdrDayEndOfWeek.rdrDayActive,
.section-calendar .rdrDayActive,
.section-calendar .rdrDayStartOfMonth.rdrDayEndOfMonth.rdrDayActive,
.section-calendar .rdrDayStartOfMonth.rdrDayActive,
.section-calendar .rdrDayEndOfMonth.rdrDayActive {
  z-index: 3 !important;
  position: relative;
  background-color: #1976d2 !important;
}

/* Style for highlighted dates from other sections */
.section-calendar .rdrDayNumber span {
  color: #333 !important;
}

/* Keep the main selection date text white */
.section-calendar .rdrDaySelected .rdrDayNumber span,
.section-calendar .rdrDayActive .rdrDayNumber span {
  color: white !important;
}

/* Styles for date dot indicators */
.section-date-dot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.section-date-dots {
  display: flex;
  justify-content: center;
  margin-top: 2px;
}

/* Fix z-index for primary selection to appear on top of dots */
.rdrDayActive, .rdrDaySelected {
  position: relative;
  z-index: 10;
}

/* Hide unused elements */
.section-date-input {
  display: none;
}

.section-date-label {
  display: none;
}

.add-section-btn {
  background: none;
  border: 1px dashed #1976d2;
  color: #1976d2;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 100%;
  font-size: 0.8rem;
  margin-top: 8px;
}

.add-section-btn:hover {
  background: #e3f2fd;
}
