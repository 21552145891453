.suggestion-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* ensure it’s on top */
}

.suggestion-modal {
  background: #fff;
  width: 600px;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.modal-header h3 {
  margin: 0;
  font-size: 1.25rem;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-content {
  padding: 16px;
  max-height: 70vh; /* limits modal content height relative to viewport */
  overflow-y: auto; /* adds vertical scroll if content exceeds height */
}

.error {
  color: red;
  margin-top: 8px;
}

.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 16px 0 0 0;
}

.suggestion-item {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
  padding-left: 10px;
}

.suggestion-item:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.suggestion-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 12px;
  border-radius: 4px;
}

.suggestion-info {
  flex: 1;
}

.suggestion-info strong {
  display: block;
  margin-bottom: 4px;
  font-size: 1rem;
}

/* New styles for recommendation sections */
.recommendation-section {
  margin-bottom: 24px;
}

.recommendation-header {
  font-size: 1.1rem;
  margin: 0 0 12px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #ddd;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.generation-date {
  font-size: 0.8rem;
  color: #777;
  font-weight: normal;
}

.community-item {
  position: relative;
  padding-top: 28px;
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 28px 12px 12px 12px;
  margin-bottom: 24px;
}

.community-badge {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #4285f4;
  color: white;
  font-size: 0.8rem;
  padding: 4px 8px;
  border-radius: 4px 4px 0 0;
  text-align: center;
  font-weight: 500;
}
