/* TripItinerary.css */

.itinerary-container {
  background: #f7f7f7;
  border-radius: 3px;
  padding: 10px;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
}

.itinerary-container h3 {
  margin-top: 0;
}

.itinerary-day-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 90%;
  overflow: auto;
}

.itinerary-day-section {
  background: #eee;
  border-radius: 4px;
  padding: 10px;
}

.itinerary-day-header {
  font-weight: bold;
  margin-bottom: 5px;
}

.itinerary-day-places {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Each itinerary item can contain multiple rows now:
   1) the top row (time, place name + notes icon, trash)
   2) optional inline-notes row
*/
.itinerary-day-places li {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  font-size: 0.9em;
}
.itinerary-day-places li:last-child {
  border-bottom: none;
}

/* Top row: flex container for time, place info, remove icon */
.itinerary-item-top-row {
  display: flex;
  align-items: center;
  width: 100%;
}

/* Time input UI styling */
.time-input-wrapper {
  margin-right: 10px;
}

.time-input {
  width: 80px;
  box-sizing: border-box;
  background-color: #ddd;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 0.9em;
  font-family: inherit; /* Inherit the font to match other elements */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Remove the default clock icon so AM/PM text is fully visible */
.time-input::-webkit-calendar-picker-indicator {
  display: none;
}

/* Place info container: place name + notes icon */
.itinerary-place-info {
  display: flex;
  align-items: center;
  flex: 1;
}

.itinerary-place-name {
  margin-right: 8px;
  /* You can remove this if you want it tighter, or leave it to separate from the notes icon */
}

/* Notes icon styling */
.itinerary-place-notes-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #888;
  font-size: 1.1em;
  margin-right: 10px; /* or adjust as needed */
}
.itinerary-place-notes-btn:hover {
  color: #555;
}

/* Remove (trash) icon button */
.itinerary-place-remove {
  background: none;
  border: none;
  cursor: pointer;
  color: #888;
  font-size: 1.1em;
  margin-left: 8px;
}
.itinerary-place-remove:hover {
  color: #e53935;
}

/* Inline notes row */
.inline-notes {
  margin-top: 5px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
}

/* Textarea for editing notes */
.notes-textarea {
  width: 100%;
  min-height: 60px;
  margin-bottom: 5px;
  font-family: inherit;
  font-size: 0.9em;
  box-sizing: border-box;
}

/* Save button styling */
.save-notes-btn {
  background: #1976d2;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.9em;
}
.save-notes-btn:hover {
  background: #1565c0;
}

/* Notes display styling */
.notes-content {
  white-space: pre-wrap;
  cursor: pointer;
  color: #444;
}

/* Button to hide itinerary */
.itinerary-toggle-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #1976d2;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 3px;
  cursor: pointer;
}
.itinerary-toggle-btn:hover {
  background: #1565c0;
}

.no-places-msg {
  color: #999;
  font-size: 0.9em;
}

/* Example CSS for inactive itinerary day */
.inactive-day {
  background-color: #f8d7da; /* pastel red / light red background */
  opacity: 0.7;
  position: relative;
}

.itinerary-day-delete {
  background: none;
  border: none;
  color: #721c24;
  cursor: pointer;
  margin-left: 10px;
}

/* Updated styles for the highlighter button */
.itinerary-day-highlight {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  font-size: 0.9em;
}

/* Not highlighted: grey icon */
.not-highlighted {
  color: grey;
}

/* Highlighted: dark yellow (or black, if you prefer) */
.highlighted {
  color: #b8860b; /* dark yellow; change to #000 for black */
}
