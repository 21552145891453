/* src/components/UserMenu.css */
.user-menu-container {
    position: relative;
    display: inline-block;
    margin: 8px; /* Add some margin around the icon container */
  }
  
  .user-icon {
    cursor: pointer;
    color: #555;
  }
  
  .user-icon:hover {
    color: #333;
  }
  
  .user-menu-dropdown {
    position: absolute;
    top: 30px;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 10px;
    min-width: 150px;
    z-index: 999;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  }
  
  .user-name {
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
  }
  
  .menu-item {
    cursor: pointer;
    padding: 5px 0;
    color: #555;
  }
  
  .menu-item:hover {
    background-color: #f1f3f4;
  }
  