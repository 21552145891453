/* src/components/LoginModal.css */

.login-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .login-modal-content {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    width: 360px;
    max-width: 90%;
    box-shadow: 0 6px 20px rgba(0,0,0,0.3);
    animation: fadeIn 0.3s ease;
    text-align: center;
  }
  
  .login-title {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 1.25rem;
    color: #333;
  }
  
  .login-error {
    background-color: #ffe4e4;
    color: #d9534f;
    padding: 8px;
    margin-bottom: 12px;
    border-radius: 4px;
    font-size: 0.9rem;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 16px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 12px;
  }
  
  .form-group label {
    margin-bottom: 4px;
    font-weight: 500;
    color: #555;
  }
  
  .form-group input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .login-button {
    background-color: #4285f4;
    color: #fff;
    border: none;
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 8px;
    transition: background-color 0.2s ease;
  }
  
  .login-button:hover {
    background-color: #3071e8;
  }
  
  .toggle-signup {
    font-size: 0.95rem;
    margin-bottom: 12px;
    color: #555;
  }
  
  .toggle-link {
    color: #4285f4;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .demo-button-container {
    margin-top: 12px;
  }
  
  .demo-button {
    background-color: #f1f3f4;
    color: #333;
    border: 1px solid #dadce0;
    padding: 10px;
    font-size: 0.95rem;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.2s ease;
  }
  
  .demo-button:hover {
    background-color: #e2e3e5;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-8px); }
    to { opacity: 1; transform: translateY(0); }
  }
  