body {
    margin: 0;
    font-family: sans-serif;
    background: #f0f2f5;
    color: #333;
  }
  
  .app-container {
    display: flex;
    height: 100vh;
  }
  
  .left-panel {
    width: 300px;
    background: #fff;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: width 0.3s ease;
  }

  .left-panel.collapsed {
    width: 40px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .expand-icon, .collapse-button {
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    width: 24px;
    height: 24px;
    z-index: 5;
  }

  .expand-icon:hover, .collapse-button:hover {
    color: #1976d2;
  }
  
  .right-panel {
    flex: 1;
    background: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  /* Mobile view adjustments */
  @media (max-width: 600px) {
    .app-container.mobile-view {
      flex-direction: column;
      height: 100vh;
    }
  
    .left-panel {
      width: 100% !important;
      border-right: none;
      height: 100vh;
    }
  
    .create-trip-btn {
      position: fixed;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      max-width: 300px;
      z-index: 100;
    }
  
    .mobile-detail-panel {
      display: block;
      width: 100%;
      height: 100vh;
    }
  
    .mobile-detail-panel .create-trip-btn {
      display: none; /* On detail view, hide create button */
    }
  
    .menu-btn {
      position: absolute;
      top: 10px;
      left: 10px;
      background: none;
      border: none;
      cursor: pointer;
      padding: 5px;
      z-index: 10;
    }
  }
  