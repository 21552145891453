.trip-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 10px;
  position: relative;
}


/* For the collapsible sections */
.trip-list-sections {
  flex: 1;
  overflow-y: auto;
  padding: 0 10px;
  padding-bottom: 60px; /* space for create button if needed */
}

.trip-section {
  margin-bottom: 15px;
}

.trip-section-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #eee;
  padding: 10px;
  border-radius: 3px;
  user-select: none;
  font-weight: bold;
}

.trip-section-header:hover {
  background: #e0e0e0;
}

.no-trips-in-section {
  color: #999;
  margin-top: 10px;
  text-align: center;
}

/* End collapsible sections */

.no-trips,
.no-selection {
  color: #999;
  margin-top: 50px;
  text-align: center;
}

.trip-list {
  /* We reuse .trip-list as the container for either current or past trips */
  margin-top: 5px;
}

/* The existing .trip-item styling remains mostly the same */
.trip-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  position: relative;
  transition: background 0.2s ease;
  cursor: pointer;
  min-height: 40px;
}
.trip-item:hover {
  background: #f7f7f7;
}
.trip-item.selected {
  background: #cfe9ff;
}

.trip-info {
  max-width: 100%;
}

.trip-name {
  font-weight: bold;
  margin-bottom: 5px;
  padding-right: 40px; /* space on right for safety */
}

.trip-dates {
  font-size: 0.9em;
  color: #666;
}

.people-count {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 0.9em;
  color: #666;
  display: flex;
  align-items: center;
}

.trip-icons {
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: none;
  gap: 5px;
}

.trip-item:hover .trip-icons {
  display: flex;
}

.icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-btn:hover {
  background: #eee;
}

.create-trip-btn {
  margin: 10px;
  padding: 10px;
  background: #1976d2;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 3px;
}
.create-trip-btn:hover {
  background: #1565c0;
}
