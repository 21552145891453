.places-list-container {
  display: flex;
  flex-direction: column;
  /* let parent (places-container) control the total height,
     so we only set overflow on the category list portion below */
  flex: 1;
  position: relative;
  overflow: auto;
  height: 100%;
}

.autocomplete-container {
  margin-bottom: 10px;
  flex-shrink: 0;
}

.category-list {
  flex: 1;
  overflow-y: auto; /* let categories/places scroll */
  margin: 0;
  padding: 0;
}

.category-section {
  margin-bottom: 10px;
  padding: 0 5px;
}

.category-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #eee;
  padding: 5px;
  border-radius: 3px;
  user-select: none;
  font-weight: bold;
}

.category-header:hover {
  background: #e0e0e0;
}

.places-list {
  list-style: none;
  padding: 0;
  margin: 5px 0 0 0;
}

.places-list li {
  padding: 5px 0;
  border-bottom: 1px solid #eee;
  font-size: 0.95em;
  /* ensure dropdown is not clipped */
  position: relative;
  overflow: visible;
}

/* Light-grey if the place is already in itinerary */
.places-list li.in-itinerary {
  background-color: #f0f0f0;
}

/* Light-blue highlight if selected */
.places-list li.selected-place {
  background: #d2e3fc;
}

.place-row-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.place-info {
  display: flex;
  flex-direction: column;
}

.place-title-row {
  display: inline-flex;
  align-items: center;
}

.place-title-row .place-name {
  margin-right: 5px;
}

.place-actions {
  display: flex;
  gap: 5px;
  align-items: center;
}

/* Notes icon styling */
.notes-icon {
  position: relative;
  margin-left: 5px;
}
.notes-icon.has-notes {
  color: #000;
}
.notes-icon.no-notes {
  color: #888;
}
.notes-icon.no-notes::after {
  content: "+";
  font-size: 0.7em;
  color: #1976d2;
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  border-radius: 50%;
  padding: 1px;
}

/* Inline notes section */
.inline-notes {
  width: 100%;
  margin-top: 6px;
  background: none;
  font-size: 0.85em;
  color: #666;
  padding: 0 10px;
  box-sizing: border-box;
  border: none;
}

.notes-content {
  white-space: pre-wrap;
  font-family: sans-serif;
  cursor: pointer;
}

.notes-content a {
  color: #1976d2;
  text-decoration: underline;
}

.notes-textarea {
  width: 100%;
  min-height: 100px;
  font-size: 0.9em;
  font-family: sans-serif;
  border: 1px solid #ccc;
  border-radius: 3px;
  resize: vertical;
  padding: 5px;
  box-sizing: border-box;
}

.save-notes-btn {
  background: #1976d2;
  color: #fff;
  border: none;
  padding: 5px 8px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 5px;
  align-self: flex-end;
}
.save-notes-btn:hover {
  background: #1565c0;
}

.no-places-msg {
  color: #999;
  font-size: 0.9em;
  margin-top: 10px;
  margin-left: 5px;
}

/* The dropdown for selecting which day to add the place */
.day-dropdown {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  z-index: 9999; /* ensure it's on top */
  right: 0;
  top: 100%;
  min-width: 120px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.day-dropdown-item {
  padding: 5px;
  cursor: pointer;
}
.day-dropdown-item:hover {
  background-color: #f0f0f0;
}

.category-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  cursor: pointer;
}

.category-header-left {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust as needed */
}

.category-header-right {
  display: flex;
  align-items: center;
}
