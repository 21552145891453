/* src/styles/TripDetail/TripDetails.css */

.trip-details-container {
  padding: 16px;
  height: 100%;
  overflow-y: auto;
}

.trip-details-location {
  color: #666;
  margin-bottom: 20px;
  font-size: 14px;
}

.details-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-weight: 500;
  color: #333;
  font-size: 14px;
}

.form-group textarea,
.form-group input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
}

.form-group textarea {
  resize: vertical;
}

.form-group textarea:focus,
.form-group input:focus {
  outline: none;
  border-color: #1976d2;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.save-details-btn {
  margin-top: 8px;
  padding: 10px 16px;
  background: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  align-self: flex-start;
}

.save-details-btn:hover {
  background: #1565c0;
}

/* Section Headers */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.section-header h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.trip-details-header {
  margin-bottom: 8px;
}

.flight-details-header {
  margin-bottom: 10px;
}

.flight-details-header h3 {
  font-size: 15px;
}

/* Flights Section Styling */
.flights-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-flight-btn {
  background: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}

.add-flight-btn:hover {
  background: #1565c0;
}

.no-flights-message {
  color: #666;
  font-style: italic;
  font-size: 12px;
}

/* Flight List Item Styling */
.flight-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.flight-list-item {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px 10px;
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.flight-list-item:hover {
  background-color: #e9ecef;
}

.flight-summary {
  display: flex;
  align-items: center;
  gap: 12px;
}

.flight-route {
  font-weight: 500;
  font-size: 12px;
}

.flight-date {
  color: #555;
  font-size: 11px;
}

.flight-number {
  color: #1976d2;
  font-weight: 500;
  font-size: 11px;
}

.remove-flight-btn {
  background: transparent;
  color: #d32f2f;
  border: none;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  padding: 0;
}

.remove-flight-btn:hover {
  background: #ffebee;
}

/* Flight Modal Styling */
.flight-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.flight-modal {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.flight-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eee;
}

.flight-modal-header h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.close-modal-btn {
  background: transparent;
  border: none;
  font-size: 24px;
  line-height: 1;
  padding: 0;
  color: #666;
  cursor: pointer;
}

.close-modal-btn:hover {
  color: #d32f2f;
}

.flight-modal-content {
  padding: 16px;
}

.flight-details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

.flight-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.save-modal-btn {
  padding: 8px 16px;
  background: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.save-modal-btn:hover {
  background: #1565c0;
}

@media (min-width: 768px) {
  .flight-details-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}